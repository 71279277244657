import React, { useState } from 'react'

import { Social } from 'components/common/social'
import Button, { ButtonSize } from 'components/common/button'

import PosterJPG from 'assets/images/home/hero.jpg'

import {
  CardContainer,
  CardImage,
  Container,
  Content,
  InfoContainer,
  SocialContainer,
  Title,
  Video,
  VideoContainer,
} from './styles'

import ComCardSvg from 'assets/images/comcard.svg'
import Waitlist from 'components/common/modal/content/waitlist'
import Modal from 'components/common/modal'

const HeroSection = () => {
  const [videoSrc, setVideoSrc] = React.useState<string>()
  const [isModalOpen, setIsModalOpen] = useState(false)

  React.useEffect(() => {
    if (window.innerWidth > 578) {
      setVideoSrc('/homepage_hero_large.mp4')
    }
  }, [])
  return (
    <Container>
      <Modal isOpen={isModalOpen} title="Join waitlist" onClose={() => setIsModalOpen(false)}>
        <Waitlist closeModal={() => setIsModalOpen(false)} />
      </Modal>
      <Content>
        <VideoContainer>
          <Video loop autoPlay playsInline muted controls={false} src={videoSrc} poster={PosterJPG} />
        </VideoContainer>
        <InfoContainer>
          <Title>
            Meet the first corporate card designed especially for your business.
          </Title>
          <div>
            <Button size={ButtonSize.MEDIUM} onClick={() => {
              setIsModalOpen(true)
              window.analytics.track('Open the waitlist modal', {
                location: 'Homepage Hero',
              })
            }}>
              Join Waitlist
            </Button>
          </div>
        </InfoContainer>
        <CardContainer>
          <CardImage src={ComCardSvg} />
        </CardContainer>
      </Content>
      <SocialContainer>
        <Social vertical lighter />
      </SocialContainer>
    </Container>
  )
}

export default HeroSection
