import * as React from 'react'
import styled from '@emotion/styled'
import { between } from 'polished'
import { Link } from 'gatsby'

type Props = {
  items: [
    {
      icon: React.ReactNode
      title: string
      description: string
      link?: string
    }
  ]
};

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`
const Item = styled.div`
  flex: 1;
  margin: 0 ${between('20px', '80px', '768px', '1680px')};
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
  
  @media screen and (min-width: 1680px) {
    margin: 0 80px;
  }
  
  @media screen and (max-width: 768px) {
    margin: 16px 0;
    
    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`
const IconContainer = styled.div`
  margin-bottom: 24px;
  
  img {
    width: 32px;
  }
`
const Title = styled.h3`
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  color: #000;
`

const TitleLink = styled(Link)`
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  color: #000;
  text-decoration: none;
`

const Description = styled.p`
  margin-top: 16px;
  color: #666;
  font-size: 16px;
  line-height: 24px;
`

const FeaturesList: React.FC<Props> = props => {
  return (
    <ItemsContainer>
      {
        props.items.map(({ title, link = null, icon, description }) => (
          <Item key={title}>
            <IconContainer>{icon}</IconContainer>
            {link == null ? <Title>{title}</Title> : <TitleLink to={link}>{title}</TitleLink>}
            <Description>{description}</Description>
          </Item>
        ))
      }
    </ItemsContainer>
  )
}

export default FeaturesList
