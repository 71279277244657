import styled from '@emotion/styled'

const BaseBlock = styled.section`
  padding: 30px 0;
`

export const Image = styled.img`
  width: 20px;
  height: 20px;
`

export const Block = styled.div`
  display: grid;
  gap: 16px;
  max-width: 360px;
  
  
 @media screen and (max-width: 1366px) {
    max-width: 310px;
 }
 
 @media screen and (max-width: 1200px) {
    max-width: 230px;
 }
 
 @media screen and (max-width: 850px) {
    max-width: max-content;
 }
`

export const LocalContainer = styled(BaseBlock)`
  display: flex;
  justify-content: space-between;
  
  @media screen and (max-width: 850px) {
    flex-direction: column;
    
    & > :not(:first-of-type) {
    margin-top: 32px;
  }
 }
`
export const Title = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
`
