import styled from '@emotion/styled'

export const Container = styled.section`
  align-self: stretch;
  position: relative;
  margin-bottom: 60px;
  
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    margin-bottom: 300px;
  }
`

export const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: calc(100vw - 310px);
  height: 648px;
  color: white;
  
  @media screen and (min-width: 1920px) {
    height: 848px;
  }
  
  @media screen and (max-width: 1500px) {
    width: calc(100vw - 250px);
  }
  
  @media screen and (max-width: 1280px) {
    height: auto;
  }
  
  @media screen and (max-width: 1024px) {
    width: calc(100vw - 100px);
  }
  
  @media screen and (min-width: 1740px) {
    width: calc((100vw - 1480px) / 2 + 1480px);
  }
  
  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

export const VideoContainer = styled.div`
  position: absolute;
  overflow: hidden;
  border-radius: 0 50px 50px 0;
  height: 100%;
  width: 100%;
`

export const Video = styled.video`
  position: absolute;
  top: 50%; 
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%; 
  min-height: 100%; 
  width: auto; 
  height: auto;
`

export const Title = styled.h1`
  font-size: 48px;
  font-weight: bold;
  line-height: 58px;
  color: white;
  
  text-shadow: 0 2px 11px rgba(27, 29, 40, 0.8);
  margin-bottom: 48px;
  
  @media screen and (max-width: 1600px) {
    font-size: 40px;
  }
  
  @media screen and (max-width: 1024px) {
    margin-bottom: 50px;
  }
  
  @media screen and (max-width: 1100px) {
    font-size: 32px;
    line-height: 45px;
  }
  
  @media screen and (max-width: 768px) {
    margin-bottom: 16px;
  }
`

export const Text = styled.p`
  max-width: 70%;
  line-height: 24px;
  color: #F9F8F7;
  
  margin-top: 32px;
  margin-bottom: 48px;
  
  text-shadow: 0 2px 11px rgba(27, 29, 40, 0.45);
  
  @media screen and (max-width: 1024px) {
    margin-top: 24px;
    margin-bottom: 50px;
  }
  
  @media screen and (max-width: 768px) {
    margin-top: 2px;
    margin-bottom: 16px;
  }
`

export const CardContainer = styled.div`
  transform: translateX(30%);
  
  @media screen and (max-width: 768px) {
    position: absolute;
    right: auto;
    left: 50%;
    bottom: 0;
    margin: 0;
    transform: translateX(-50%) translateY(75%);
  }
`

export const CardImage = styled.img`
  height: 448px;
  
  box-shadow: 0 3px 17px rgba(67, 67, 67, 0.16);
  border-radius: 16px;
  
  @media screen and (max-width: 1280px) {
    height: auto;
    margin: 30px 0;
  }

  @media screen and (max-width: 768px) {
    width: auto;
    height: 400px;
    margin: 0;
  }
`

export const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  
  padding-left: 95px;

  @media screen and (min-width: 1740px) {
    max-width: 310px;
  }
  @media screen and (max-width: 1500px) {
    padding-left: 70px;
  }
  @media screen and (max-width: 1024px) {
    display: none;
  }
`

export const InfoContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  
  @media screen and (max-width: 1280px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  @media screen and (max-width: 768px) {
    margin-left: 17px;
    padding-top: 120px;
    padding-bottom: 120px;
  }
  
  @media screen and (min-width: 1680px) {
    margin-left: calc((100vw - 1480px) / 2);
  }
  
  button {
    align-self: flex-start;
  }
`
