import styled from '@emotion/styled'
import { BlockTitle, Text } from 'components/common/styles'
import ReadMoreLink from 'components/common/read-more-link'
import RotateSvg from 'components/common/rotate-svg'
import { Image } from '../styles'
import { css, keyframes } from '@emotion/core'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 80px 0;
  width: 100%;
  max-width: 1680px;
  
  @media screen and (max-width: 768px) {
     margin: 60px 0;
  }
`

const BaseBlock = styled.div`
  padding: 30px 0;
  
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const Circle = styled(RotateSvg)`
  // position: absolute;
  // z-index: -1;
  width: 100%;
  animation: ${rotation} linear 60s infinite;
  transform-origin: center center;
`

export const ChooseCardBlock = styled(BaseBlock)``

export const Title = styled(BlockTitle)`
  text-align: center;
  margin-bottom: 60px;
`

export const Content = styled.div`
  font-size: 100px;
  
  position: relative;
  z-index: 1;
  width: 7.02em;
  
  margin-right: 1.6em;
  margin-left: 2.1em;
  
  @media screen and (max-width: 1280px) {
    font-size: 80px;
  }
  
  @media screen and (max-width: 1000px) {
    font-size: 70px;
  }
  
  @media screen and (max-width: 768px) {
    font-size: calc((100vw - 34px) / 7.02);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
`

type Props = {
  isAnimated?: boolean
}

const AbstractImageContainer = styled.div<Props>`
  position: absolute;
  // width: 6.2em;
  transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
  display: none;
  
  &:after {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 1.92em;
    height: 3em;
    display: block;
    content: "";
    box-shadow: 0 28px 39px rgba(10, 69, 65, 0.1);
    border-radius: 16px;
    // background-color: #FFF;
  }
`

const movement = (from, to) => keyframes`
    from {
       transform: translateY(${from}em);
    }
    to {
      transform: translateY(${to}em);
    }
`

const makeAnimation = (from, to, isAnimated) => css`
  animation: ${isAnimated ? css`${movement(from, to)} 1s ease-in-out` : 'none'};
  animation-fill-mode: forwards;
  animation-delay: 2s;
`

export const ImagesContainer = styled.div`
  position: absolute;
  display: flex; 
  align-items: center; 
  justify-content: center;
  width: 7.02em;
  height: 7.02em;
  top: 0;
  left: 0;
`

export const Cards = styled.img`
  width: 6.27em;
  height: 5.43em;
`

export const ImageContainerOne = styled(AbstractImageContainer)`
  transform: translateY(.7em);
  // ${props => makeAnimation(.35, .7, props.isAnimated)};
  
  z-index: 4;
`

export const ImageContainerTwo = styled(AbstractImageContainer)`

  z-index: 7;
`

export const ImageContainerThree = styled(AbstractImageContainer)`
  transform: translateY(-.7em) matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
  // ${props => makeAnimation(-.35, -.7, props.isAnimated)};
  
  z-index: 10;
`

export const CardImage = styled(Image)<Props>`
  width: 1.92em;
  height: 3em;
  
  // display: none;
`
export const FirstCard = styled(CardImage)`
  filter: blur(0.02em);
  
`

export const SecondCard = styled(CardImage)`
  filter: blur(.005em);
`

export const ThirdCard = styled(CardImage)<Props>`
`

export const InfoItems = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  
  @media screen and (max-width: 768px) {
    position: relative;
    margin-top: 40px;
    font-size: 100px;
    padding: 0 17px;
  }
`

export const Info = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  
  @media screen and (max-width: 768px) {
    position: static;
  }
`

const dotAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(0, 160, 149, 0.5);
  }
  70% {
    box-shadow: 0 0 0 .08em rgba(0, 160, 149, 0);
  }
   70% {
    box-shadow: 0 0 0 .08em rgba(0, 160, 149, 0);
  }
`

const AbstractInfo = styled.div`
  position: absolute;
  &:before {
    content: '';
    position: absolute;
    width: 11px;
    height: 11px;
    background: #00A095;
    border-radius: 50%;
    animation: ${dotAnimation} ease-in-out 2s infinite;
  }
  
  @media screen and (max-width: 768px) {
    position: relative;
    &:before {
      display: none;
    }
  }
`

export const ConstructionInfo = styled(AbstractInfo)`
  

  @media screen and (min-width: 769px) {
    right: 6.24em;
    top: 1.2em;
    text-align: right;
    
    ${Info} {
      right: .5em;
      top: -.08em;
      text-align: left;
      width: 2.32em;
    }
  }
`
export const InteriorDesignInfo = styled(AbstractInfo)`
  @media screen and (min-width: 769px) {
    left: 6.38em;
    top: 5.38em;
    
    ${Info} {
      left: .2em;
      top: -0.08em;
      width: 2.32em;
    }
  }
  
  @media screen and (max-width: 768px) {
    padding-top: 20px;
  }
`

export const InfoTitle = styled.div`
  font-size: .2em;
  font-weight: 600;
  line-height: 1.4;
  color: #000;
`

export const Description = styled(Text)`
  font-size: .16em;
  margin: 1em 0;
  color: #666668;
`

export const ReadMore = styled(ReadMoreLink)`
  display: inline-flex;
  align-self: flex-start;
  font-size: .16em;
`
