import styled from '@emotion/styled'
import { Container } from 'components/common/styles'

type ContainerProps = {
  position: 'left' | 'right'
}

export const LocalContainer = styled(Container)<ContainerProps>`
  display: flex;
  flex-direction: ${props => props.position === 'right' ? 'row-reverse' : 'row'};
  align-items: center;
  width: 100%;
  
  margin-top: 60px;
  margin-bottom: 115px;
  
  .readMore {
    align-self: flex-start;
  }
  
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }  
`

export const Content = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media screen and (min-width: 786px) { 
     margin-right: 48px;
  }

  & > * + * {
    margin-top: 24px;
  }
`

type ImageContainerProps = {
  align: 'left' | 'right'
}

export const ImageContainer = styled.div<ImageContainerProps>`
  flex: 1;
  text-align: ${props => props.align};
`

export const Image = styled.img`
  max-width: 100%;
  padding-top: 45px;

  @media screen and (min-width: 768px) {
    width: 80%;
    padding-top: 0;
  }
`
