import React from 'react'
import Layout from 'layouts/default'
import FeaturesSections from 'components/pages/home/features'

import HeroSection from 'components/pages/home/hero'
import MerchantSection from 'components/pages/home/merchants'
import CardsSection from 'components/pages/home/cards'
import QualifySection from 'components/qualify'
import richSnippet from '../components/common/rich-snippet'
import { createHomeSchema } from '../utils/richSnippets'

type Props = {
  location: string
}
const Index: React.FC<Props> = () => {
  React.useEffect(() => {
    window.analytics.page('Homepage')
  }, [])
  return (
    <Layout helmetChildren={richSnippet(createHomeSchema())}>
      <HeroSection />
      <FeaturesSections />
      {/*<AboutSection />*/}
      <CardsSection />
      {/*<RewardsSection />*/}
      <MerchantSection />
      <QualifySection />
    </Layout>
  )
}

export default Index
