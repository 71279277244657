import React from 'react'

import { BlockTitle, Text } from 'components/common/styles'
import { Content, Image, ImageContainer, LocalContainer } from './styles'

import ReadMoreLink from 'components/common/read-more-link'

import MerchantImage from 'assets/images/home/merchant.svg'

const MerchantSection = () => (
  <LocalContainer position="right">
    <Content>
      <BlockTitle>ComCard for merchants</BlockTitle>
      <Text>
        Join our growing merchant network to attract and retain customers with value-based loyalty programs, while
        reaping the benefits and savings of ComCard’s industry leading rewards card.
      </Text>

      <ReadMoreLink to="/merchants">Read More</ReadMoreLink>
    </Content>
    <ImageContainer align="left">
      <Image src={MerchantImage} />
    </ImageContainer>
  </LocalContainer>
)

export default MerchantSection
