import React, { useState } from 'react'

import { Waypoint } from 'react-waypoint'

import {
  Cards,
  ChooseCardBlock,
  Circle,
  ConstructionInfo,
  Container,
  Content,
  Description,
  FirstCard,
  ImageContainerOne,
  ImageContainerThree,
  ImageContainerTwo,
  ImagesContainer,
  Info,
  InfoItems,
  InfoTitle,
  InteriorDesignInfo,
  ReadMore,
  SecondCard,
  ThirdCard,
  Title,
} from './styles'

import ComCardSVG from 'assets/images/comcard.svg'
import CardsPNG from 'assets/images/home/cards-temp.png'


const CardsSection = () => {
  const [isAnimated, setIsAnimated] = useState(false)

  return (
    <Waypoint onEnter={() => setIsAnimated(true)}>
      <Container>
        <ChooseCardBlock>
          <Title>Choose the card designed for your business.</Title>

          <Content>
            <Circle />
            <ImagesContainer>
              <Cards src={CardsPNG} />
              <ImageContainerOne isAnimated={isAnimated}>
                <FirstCard src={ComCardSVG} isAnimated={isAnimated} />
              </ImageContainerOne>
              <ImageContainerTwo isAnimated={isAnimated}>
                <SecondCard src={ComCardSVG} isAnimated={isAnimated} />
              </ImageContainerTwo>
              <ImageContainerThree isAnimated={isAnimated}>
                <ThirdCard src={ComCardSVG} isAnimated={isAnimated} />
              </ImageContainerThree>
            </ImagesContainer>
            <InfoItems>
              <ConstructionInfo>
                <Info>
                  <InfoTitle>Construction</InfoTitle>
                  <Description>
                    Boost your bottom line with double-digit rewards from top suppliers and vendors.
                  </Description>
                  <ReadMore to="/cards">Read More</ReadMore>
                </Info>
              </ConstructionInfo>
              <InteriorDesignInfo>
                <Info>
                  <InfoTitle>Interior design</InfoTitle>
                  <Description>
                    Maximize savings on qualified purchases from premium vendors & retailers.
                  </Description>
                  <ReadMore to="/cards">Read More</ReadMore>
                </Info>
              </InteriorDesignInfo>
            </InfoItems>
          </Content>
        </ChooseCardBlock>
      </Container>
    </Waypoint>
  )
}

export default CardsSection
