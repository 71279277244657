import styled from '@emotion/styled'

const BaseBlock = styled.div`
  padding: 30px 0;
`

export const ColoredBlock = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  background: #e4f1ef;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ElementTitle = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
`

export const Image = styled.img`
  max-width: 100%;
`

/******************
 * WriteAboutUs Block
 ******************/

export const WriteAboutBlock = styled(BaseBlock)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
`

export const WriteAboutTitle = styled(ElementTitle)`
  font-size: 22px;
  line-height: 40px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
`

export const WriteAboutElements = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`

export const WriteAboutElement = styled(Image)`
  margin: 15px;
  
  @media screen and (max-width: 768px) {
    margin: 15px 0;
  }
`
