import React from 'react'

const RotateSvg = (props) => (
  <svg {...props} viewBox="0 0 780 780" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.45" cx="390" cy="390" r="389.5" stroke="#00A095" strokeLinejoin="round"
            strokeDasharray="8 8" />
    <circle opacity="0.25" cx="390.082" cy="390.082" r="351.082" fill="#6BCBC4" />
    <circle opacity="0.28" cx="391" cy="391" r="275" fill="#6BCBC4" />
  </svg>
)

export default RotateSvg
