import * as React from 'react'
import { LocalContainer } from './styles'
import FeaturesList from 'components/features-list'
import { Container } from 'components/common/styles'

import MoneybagSvg from 'assets/images/icons/money_bag.svg'
import PercentSvg from 'assets/images/icons/percent.svg'
import NoFeesSvg from 'assets/images/icons/crossed_dollar.svg'

const FeaturesSections = () => (
  <Container>
    <LocalContainer>
      <FeaturesList items={[
        {
          icon: (<img src={PercentSvg} />),
          title: 'Top rewards that make sense',
          link: '/rewards',
          description: 'Double-digit cash back and rewards from preferred vendors, suppliers and retailers.',
        },
        {
          icon: (<img src={MoneybagSvg} />),
          title: 'Tailored financial management tools',
          link: '/cards',
          description: 'Seamless integration with banking and accounting apps, and other features',
        },
        {
          icon: (<img src={NoFeesSvg} />),
          title: 'Say goodbye to fees',
          link: '/cards',
          description: 'We don’t believe in unnecessary fees that traditional corporate cards tack on.',
        },
      ]} />
    </LocalContainer>
  </Container>
)

export default FeaturesSections
